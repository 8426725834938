#root {
  height: 100%;
}

.border-rounded,
.ant-image.border-rounded .ant-image-img,
.ant-image.border-rounded .ant-image-mask {
  border-radius: 100%;
}

/* layer priority */
@media (max-width: 991px){
  .ant-layout-sider {
    z-index: 9999999 !important; 
  }
}
.ant-message, 
.ant-picker-dropdown, 
.ant-select-dropdown,
.ant-tooltip,
.ant-dropdown {
  z-index: 999999 !important;
}

.ant-modal-wrap, 
.ant-modal-mask, 
.ant-image-preview-wrap,
.ant-image-preview-mask,
.ant-drawer {
  z-index: 99999 !important;
}

/* header */
.ant-layout-header {
  z-index: 9999999;
}

@media only screen and (max-width: 767px){
  .atbd-top-dropdwon {
    min-width: auto !important;
  }
}

/* user menu */
/* .user-popover {
  min-width: 220px;
} */

/* menu */
.ant-layout-sider .ant-menu-item-group-title {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: rgba(255,255,255,.38);
  padding: 0 15px;
}
.ant-layout-sider .ant-menu-item-group {
  margin-bottom: 28px;
}
.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item {
  width: calc(100% - 17px) !important;
}
.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item span, 
.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item span a,
.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-submenu span, 
.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-submenu span a {
  padding-left: 0 !important;
}

.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item > span.anticon, 
.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-submenu-title > span.anticon {
  width: auto !important;
}

/* btn */
.ant-btn-link {
  color: #48B9C7;
}
.ant-btn-link:not([disabled]):hover {
  color: #378e99 !important;
}
.ant-btn-link[disabled]:hover {
  color: rgba(0, 0, 0, 0.25) !important;
}
.ant-btn-primary:not([disabled]) {
  color: #FFF !important;
  /* text-shadow: 0 1px #757575; */
}
.ant-btn-light:not([disabled]):hover {
  background: #e2e6ea !important;
}
.ant-btn-dangerous:not([disabled]):hover {
  color: #E30D0F !important;
}

.ant-btn-dashed:not([disabled]):hover {
  color: #378e99 !important;
  border: 1px dashed #378e99;
}

.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
  color: rgba(0, 0, 0, 0.25) !important;
}

.ant-btn-primary[disabled], 
.ant-btn-primary[disabled]:hover, 
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: #fff !important;
}

  /* small */
.ant-btn-sm {
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
}

.ant-btn > svg.feather + span {
  margin-left: 4px;
}

  /* btn in space */
.ant-space-item .ant-btn span {
  font-size: unset;
}

/* card header skeleton */
.ant-card-head .ant-card-head-title .ant-skeleton-title {
  margin: 0;
}

/* card header */
.ant-card-head-title span {
  font-size: 13px;
  margin: 0;
}

  /* badge fix */
.ant-card-head-title span.ant-badge,
.ant-card-head-title span.ant-badge span {
  margin: 0;
  color: #FFF;
}

/* spinner */
.ant-card-body .sd-spin .ant-spin,
.ant-card-body .spin .ant-spin {
  left: unset;
  top: unset;
  transform: unset;
}

.ant-card-body .ant-page-header,
.ant-modal-body .ant-page-header {
  padding: 0px 0px 11px 0px;
}
.ant-page-header .ant-page-header-heading-title {
  color: #BF8630;
}

/* text colors */
.text-light {
  color: #9E9E9E !important;
}
.ant-dropdown .text-light:hover {
  background-color: rgb(146, 153, 184, .10) !important;
}

.text-info {
  color: #2C99FF !important;
}
a.text-info:hover {
  color: #0D79DF !important;
}
.ant-dropdown .text-info:hover {
  background-color: rgb(44, 153, 255, .10) !important;
}

.text-success {
  color: #20c997 !important;
}
a.text-success:hover {
  color: #0CAB7C !important;
}
.ant-dropdown .text-success:hover {
  background-color: rgba(32, 201, 151, .10) !important;
}

.text-warning {
    color: #FA8B0C !important;
}
a.text-warning:hover {
  color: #D47407 !important;
}
.ant-dropdown .text-warning:hover {
  background-color: rgb(250, 139, 12, .10) !important;
}

.text-danger {
    color: #FF4D4F !important;
}
a.text-danger:hover {
  color: #E30D0F !important;
}
.ant-dropdown .text-danger:hover {
  background-color: rgb(255, 77, 79, .10) !important;
}

/* dropdown */
.ant-dropdown {
  padding: 0 !important;
}

.ant-dropdown .ant-divider {
  margin: 9px 0;
}

.ant-select-item,
.ant-dropdown a {
  transition: background 0.3s;
}

.ant-select-item:hover,
.ant-dropdown a:hover {
  background: #48B9C720 none repeat scroll 0% 0%;
  color: #48B9C7;
}

.ant-select-arrow {
  top: 35%;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 34px !important;
}

/* dropdown-menu */
.ant-dropdown-menu {
  min-width: unset;
}
.ant-dropdown-menu li {
  padding: 0;
}

/* picker */
.ant-picker {
  width: 160px;
  min-width: unset;
}
.ant-picker.ant-picker-range {
  width: 240px;
}

.ant-item-has-error .ant-input-number, 
.ant-form-item-has-error .ant-picker {
  border-color: #f5222d !important;
}

/* table header */
.ant-table-thead > tr > th {
  background: #F5F5F5 !important;
}
.ant-table-thead th.ant-table-column-has-sorters:hover, 
.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #EEEEEE !important;
}
.ant-table-column-sorter,
.ant-table-filter-trigger {
  color: #9E9E9E !important;
}
.ant-table-column-sorter.active,
.ant-table-filter-trigger.active {
  color: #BF8630 !important;
}
.ant-table-filter-trigger {
  margin: 0 0 0 4px !important;
  padding: 5px 5px;
}

/* table pagination */
.ant-table-wrapper .ant-table-pagination {
  margin: 0 0 14px 0 !important;
}
.ant-table-wrapper .ant-table + .ant-table-pagination {
  margin: 14px 0 0 !important;
}
.ant-table-wrapper .ant-table-pagination.mini {
  margin-top: 0 !important;
  margin-bottom: 7px !important;
}
.ant-table-wrapper .ant-table + .ant-table-pagination.mini {
  margin-top: 7px !important;
  margin-bottom: 0 !important;
}
.ant-table-pagination li.ant-pagination-total-text {
  display: block;
  color: #757575;
  margin: 4px 0 0 0;
}

/* table filter */
.ant-table-filter-dropdown-search {
  padding: 12px 12px !important;
}
.ant-table-filter-dropdown-search-input {
  padding: 5px 6px 5px 11px;
}
.ant-table-filter-dropdown > .ant-dropdown-menu li {
  padding: 5px 12px;
}

/* row expandable */
.ant-table-row-expand-icon {
  color: #BF8630 !important;
  border: 1px solid #BF8630 !important;
}
.ant-table-row-expand-icon:focus, 
.ant-table-row-expand-icon:hover, 
.ant-table-row-expand-icon:active {
  color: #BF8630 !important;
  border: 1px solid #BF8630 !important;
}

/* pagination */
.ant-pagination-mini .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-pagination-mini .ant-pagination-item-link {
  height: 25px !important;
}
.ant-pagination-mini .ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-pagination-mini .ant-pagination-item-link {
  line-height: 25px !important;
}
.ant-pagination-item {
  border: 1px solid #CBCED6;
}
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-jump-prev,
.ant-pagination .ant-pagination-jump-next,
.ant-pagination .ant-pagination-options .ant-select-selector {
  border: unset !important;
}
.ant-pagination-prev button, .ant-pagination-next button,
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, 
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #757575;
}

/* transfer */
.ant-transfer {
  overflow: auto;
}
.ant-transfer-list .ant-input.ant-transfer-list-search {
  padding: 4px 11px;
}
.ant-transfer-list .ant-transfer-list-search-action {
  line-height: 32px;
}
.ant-transfer-list .ant-table-body .ant-table-row:hover {
  cursor: pointer;
}
.ant-transfer-list .ant-pagination.mini,
.ant-transfer-list .ant-table-wrapper .ant-table + .ant-table-pagination.mini {
  margin: 6px !important;
}

/* dropdown list */
.dropdown-list .ant-list-item {
  padding: 10px 12px;
}
.dropdown-list .ant-list-item-meta {
  padding: 0;
  border-bottom: none;
}
.dropdown-list .ant-list-item-meta .ant-list-item-meta-description {
  line-height: 1.25;
  padding-right: 12px;
}

/* drawer */
.ant-drawer-footer {
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
}

/* badge */
.ant-badge-count,
.ant-badge-dot {
  background: #ff4d4f;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  color: #ff4d4f !important;
}

/* user efficiency */
.user-efficiency {
  height: 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.user-efficiency .ant-badge {
  font-size: 12px;
}
.user-efficiency .ant-badge-not-a-wrapper {
  text-wrap: nowrap;
}
.user-efficiency .ant-badge .ant-badge-status-text,
.user-efficiency.dark .ant-badge .ant-badge-status-text {
  color: #A8AAB3;
}
.user-efficiency .ant-badge .ant-badge-status-text b,
.user-efficiency.dark .ant-badge .ant-badge-status-text b {
  color: #FFF;
  font-weight: 500;
}
.user-efficiency.light .ant-badge .ant-badge-status-text {
  color: #999999;
}
.user-efficiency.light .ant-badge .ant-badge-status-text b {
  color: #757575;
}
.user-efficiency .ant-progress-inner {
  overflow: visible;
  background-color: #EEEEEE;
  border: 1px solid 1px solid rgba(0, 0, 0, .1);
}
.user-efficiency .ant-progress-inner, 
.user-efficiency .ant-progress-inner .ant-success-bg {
  border-radius: 5px;
}
.user-efficiency .ant-progress-inner .ant-progress-bg {
  /* border-right: 1px solid #2C99FF; */
  background-color: unset;
  border-radius: unset;
  z-index: 1000;
}
.user-efficiency .ant-progress-inner .ant-progress-bg:after {
  position: absolute;
  right: -7px;
  top: -3px;
  content: '';
  width: 14px; 
  height: 14px; 
  background: #2C99FF;
  border-radius: 50%;
  /* border: 1px solid rgba(0, 0, 0, .25); */
  /* box-shadow: 
    inset 1px 1px 0px rgba(255, 255, 255, .25), 
    inset -1px -1px 0px rgba(0, 0, 0, .15); */

}

/* tooltip */
.ant-tooltip .ant-tooltip-inner {
  color: #FFF;
  background-color: #000000bf;
  border-radius: 3px;
  display: flex;
  align-items: center;
  border: none;
  box-shadow: 0 8px 15px #BDBDBD15;
}
.ant-tooltip .ant-tooltip-arrow::after {
  background: unset;
}
.ant-tooltip .ant-tooltip-arrow .ant-tooltip-arrow-content {
  background: #000000bf;
}

/* input icons */
.ant-input-affix-wrapper .ant-input-prefix svg, 
.ant-input-affix-wrapper .ant-input-suffix svg {
  color: #BF8630;
}

/* popover */
.ant-popover-inner .ant-popover-inner-content {
  width: auto;
}
.ant-popover-inner .ant-popover-inner-content a {
  color: #48B9C7;
}

/* popover card */
.ant-popover.popover-card .ant-popover-inner {
  box-shadow: 0 5px 20px #BDBDBD9F;
  border-radius: 6px;
}
.ant-popover.popover-card .ant-popover-inner .ant-popover-title {
  padding: 0;
}
.ant-popover.popover-card .ant-popover-inner p {
  margin-bottom: 5px;
}
.ant-popover.popover-card .ant-popover-inner p:last-of-type {
  margin-bottom: 0;
}

/* comment */
.ant-comment-avatar:hover {
  cursor: default;
}

/* truncate group */
.truncate-group-body {
  display: flex;
  align-items: center;
}

.truncate-group-flexible {
  /* flex: 1; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-group-fixed {
  white-space: nowrap;
}

.feather {
  vertical-align: sub;
}